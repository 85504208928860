"use client";

import Text from "@/lib/components/Text";
import { useTranslation } from "@/lib/i18n/client";
import { LanguageSettings } from "@/lib/i18n/settings";
import { LanguageParams } from "@/lib/types/PageProps";
import Link from "next/link";
import LanguageSelectorIcon from "./LanguageSelectorIcon";
import { ReadonlyURLSearchParams } from "next/navigation";
import { authAtom } from "@/lib/jotai/auth/authStore";
import { useAtomValue } from "jotai";
import { checkoutFormsAtom } from "@/lib/jotai/checkout/checkoutStore";
import { getOnLanguageLink } from "./onLanguageLink";
import { GoogleTagManagerEvents } from "@/lib/utils/googleTagEvents";

 // Extract city name from the URL
 // eslint-disable-next-line @typescript-eslint/no-unused-vars
 export const getCityFromURL = () => {

  // Ensure the function runs only on the client-side
  if (typeof window === "undefined") {
      return null;
  }

  const url = new URL(window.location.href); // Get the current URL
  const pathname = url.pathname; // Extract the pathname from the URL
  
  // Match '/<lang>/s/<city>' where <lang> is any two-letter language code
  const cityMatch = pathname.match(/^\/(?:[a-z]{2}\/)?s\/([^/]+)/);
  
  // Decode and return the city if matched, replacing '+' with spaces
  return cityMatch ? decodeURIComponent(cityMatch[1]).replace(/\+/g, " ") : null;
};


const currentCityfetch: string | null = getCityFromURL();
 // eslint-disable-next-line @typescript-eslint/no-unused-vars
const currentCity: string = currentCityfetch ?? "nocity"; // Fallback to "default value" if null

type Props = {
    targetLanguage: string;
    entryData: LanguageSettings;
    currentPath: string;
    searchParams: ReadonlyURLSearchParams;
    languageMappings: Record<string, string[][]>;
    current?: boolean;
    currentCity: string;
} & LanguageParams;

const LanguageSelectorItem = ({
    lang: currentLanguage,
    targetLanguage,
    entryData,
    currentPath,
    searchParams,
    languageMappings,
    current = false,
    currentCity,
}: Props) => {
    const { t } = useTranslation(currentLanguage, "menu");
    const auth = useAtomValue(authAtom);
    const checkoutForms = useAtomValue(checkoutFormsAtom);
    const userIdForGTM = auth.isLoggedIn ? auth.userInfo._id : undefined;

    return (
        <li className={`flex ${current && "border-t border-b border-gray-300"} hover:bg-gray-200 cursor-pointer`}>
            <Link
                className={`flex flex-grow items-center ${current ? "p-6" : "py-4 px-6"}`}
                onClick={(event) => {
                    GoogleTagManagerEvents.clickOnLanguageSwitcher(currentLanguage, targetLanguage, userIdForGTM);
                    return getOnLanguageLink(
                        currentPath,
                        searchParams,
                        languageMappings,
                        targetLanguage,
                        currentLanguage,
                        currentCity,
                        auth,
                        checkoutForms,
                    )(event);
                }}
                href="javascript:void(0);"
            >
                <LanguageSelectorIcon flagIcon={entryData.flagIcon} name={entryData.localName} dark={true} />
                <div className="flex-grow">
                    <Text className="ml-2">{entryData.localName}</Text>
                    {current && <Text className="text-gray-500"> ({t("selected")})</Text>}
                </div>
                {/* <div><Text>{entryData.currency}{entryData.currencySign && ` ${entryData.currencySign}`}</Text></div> */}
            </Link>
        </li>
    );
};

export default LanguageSelectorItem as unknown as (props: Props) => JSX.Element;
