"use client";

import React from "react";
import { usePathname } from "next/navigation";
import { useAtomValue } from "jotai";
import { authAtom } from "@/lib/jotai/auth/authStore";

type FooterLayoutProps = {
    children: React.ReactNode;
};

export default function FooterLayout({ children }: FooterLayoutProps) {
    const pathname = usePathname();
    const auth = useAtomValue(authAtom);
    const { isLoggedIn } = auth;
    const isCheckoutRoute = pathname.includes("checkout");
    return <>{(!isCheckoutRoute || isLoggedIn) && children}</>;
}
