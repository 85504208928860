import(/* webpackMode: "eager" */ "/opt/buildhome/repo/node_modules/jotai/esm/react.mjs");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/node_modules/jotai/esm/react/utils.mjs");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/buildhome/repo/src/app/[lang]/(with-footer)/footer-layout.tsx");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/src/lib/components/CloudImage/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/buildhome/repo/src/lib/components/Footer/FooterLanguage.tsx");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/src/lib/components/Menu/MenuClient.tsx");
