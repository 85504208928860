import { BookingType } from "@/lib/types/Search";
import { PurchaseEvent } from "@/app/[lang]/(with-footer)/checkout/types";
import { ReservationDoc } from "@/lib/types/Reservation";

export type RoomItem = {
    item_id: string;
    hotel_name: string;
    hotel_type: "Hotel" | "Hostel" | "Apart" | string;
    hotel_room_name: "Single" | "Double Room";
    hotel_city: string;
    price: number;
};
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace GoogleTagManagerEvents {
    /**
     * Event that triggers on page render
     *
     * @param lang
     * @param user_id
     */
    export function viewPage(lang: string, user_id: string | undefined = undefined) {
        // eslint-disable-next-line
        if (!(window as any)?.dataLayer || !Array.isArray((window as any)?.dataLayer)) {
            // eslint-disable-next-line
            (window as any).dataLayer = [];
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).dataLayer.push({
            event: "page_view",
            user_id: user_id,
            language: lang,
        });
    }

    /**
     * Monitoring click events on buttons or links
     * that lead to the regsitration page
     *
     * @param lang string
     * @param page_type "header" | "login_popup" | "checkout" | "register_now"
     */
    export function gotoRegisterPageClick(
        lang: string,
        page_type: "header" | "login_popup" | "checkout" | "register_now",
    ) {
        // eslint-disable-next-line
        if (!(window as any)?.dataLayer || !Array.isArray((window as any)?.dataLayer)) {
            // eslint-disable-next-line
            (window as any).dataLayer = [];
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).dataLayer.push({
            event: "click_btn",
            type: "btn_sign_up",
            user_id: undefined,
            language: lang,
            page_type: page_type,
        });
    }

    /**
     * Send google tag manager event for the successfull
     * submit of the registration process
     *
     * @param lang string
     * @param user_id string | undefined
     * @param page_type "sign_up_page" | "checkout"
     * @param signup_method "Direct" | "Google"
     */
    export function submitRegisterPageInfo(
        lang: string,
        page_type: "sign_up_page" | "checkout",
        user_id: string | undefined = undefined,
        signup_method: "Direct" | "Google" = "Direct",
    ) {
        // eslint-disable-next-line
        if (!(window as any)?.dataLayer || !Array.isArray((window as any)?.dataLayer)) {
            // eslint-disable-next-line
            (window as any).dataLayer = [];
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).dataLayer.push({
            event: "sign_up",
            method: signup_method,
            user_id: user_id,
            language: lang,
            page_type: page_type,
        });
    }

    /**
     * Monitoring the click event that lead to the login page redirect
     *
     * @param lang string
     * @param user_id string | undefined
     * @param page_type "header" | "sign_up_page" | "checkout"
     */
    export function clickOnGoToLogin(
        lang: string,
        page_type: "header" | "sign_up_page" | "checkout",
        user_id: string | undefined = undefined,
    ) {
        // eslint-disable-next-line
        if (!(window as any)?.dataLayer || !Array.isArray((window as any)?.dataLayer)) {
            // eslint-disable-next-line
            (window as any).dataLayer = [];
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).dataLayer.push({
            event: "click_btn",
            type: "btn_log_in",
            language: lang,
            user_id: user_id,
            page_type: page_type,
        });
    }

    /**
     * Send google tag manager event for the successfull login process
     *
     * @param lang string
     * @param page_type "header" | "sign_up_page" | "checkout"
     * @param login_method "Direct" | "Google"
     * @param user_id string | undefined
     */
    export function submitLoginPageInfo(
        lang: string,
        page_type: "header" | "sign_up_page" | "checkout",
        login_method: "Direct" | "Google" = "Direct",
        user_id: string | undefined = undefined,
    ) {
        // eslint-disable-next-line
        if (!(window as any)?.dataLayer || !Array.isArray((window as any)?.dataLayer)) {
            // eslint-disable-next-line
            (window as any).dataLayer = [];
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).dataLayer.push({
            event: "login",
            method: login_method,
            language: lang,
            user_id: user_id,
            page_type: page_type,
        });
    }

    /**
     * Monitoring the click event that lead to the forget password
     *
     * @param lang string
     * @param page_type "header" | "sign_up_page" | "checkout"
     * @param user_id string | undefined
     */
    export function clickForgetPassword(
        lang: string,
        page_type: "header" | "sign_up_page" | "checkout",
        user_id: string | undefined = undefined,
    ) {
        // eslint-disable-next-line
        if (!(window as any)?.dataLayer || !Array.isArray((window as any)?.dataLayer)) {
            // eslint-disable-next-line
            (window as any).dataLayer = [];
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).dataLayer.push({
            event: "click_btn",
            type: "forgot_password",
            language: lang,
            user_id: user_id,
            page_type: page_type,
        });
    }

    /**
     * Monitoring the click event that lead to either faq | contact_us | becom_a_host
     *
     * @param lang string
     * @param page_type "header" | "sign_up_page" | "checkout"
     * @param user_id string | undefined
     */
    export function clickFAQorBecomeHostorContact(
        lang: string,
        page_type: "become_a_partner" | "faq" | "contact",
        user_id: string | undefined = undefined,
    ) {
        // eslint-disable-next-line
        if (!(window as any)?.dataLayer || !Array.isArray((window as any)?.dataLayer)) {
            // eslint-disable-next-line
            (window as any).dataLayer = [];
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).dataLayer.push({
            event: "click_btn",
            type: page_type,
            language: lang,
            user_id: user_id,
            page_type: "header",
        });
    }

    /**
     * Monitoring the click on the language switcher from old to new language
     *
     * @param lang string
     * @param user_id string | undefined
     */
    export function clickOnLanguageSwitcher(
        lang: string,
        new_language: string,
        user_id: string | undefined = undefined,
    ) {
        // eslint-disable-next-line
        if (!(window as any)?.dataLayer || !Array.isArray((window as any)?.dataLayer)) {
            // eslint-disable-next-line
            (window as any).dataLayer = [];
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).dataLayer.push({
            event: "click_btn",
            type: `language_switch_to_${new_language}`,
            language: lang,
            user_id: user_id,
            page_type: "header",
        });
    }

    /**
     * Monitor searches triggerd on the home page and results page
     *
     * @param lang
     * @param page
     * @param search_term
     * @param number_of_days
     * @param page_type
     * @param check_in
     * @param check_out
     * @param number_of_guests
     * @param user_id
     */
    export function clickOnSearchHotels(
        lang: string,
        page: "search_from_homepage" | "search_from_search_result_page",
        search_term: string,
        number_of_days: number,
        page_type: "homepage" | "search_result_page",
        check_in: Date | string,
        check_out: Date | string,
        number_of_guests: number,
        user_id: string | undefined = undefined,
    ) {
        // eslint-disable-next-line
        if (!(window as any)?.dataLayer || !Array.isArray((window as any)?.dataLayer)) {
            // eslint-disable-next-line
            (window as any).dataLayer = [];
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).dataLayer.push({
            event: "search",
            type: page,
            search_term: search_term,
            number_days: number_of_days,
            language: lang,
            page_type: page_type,
            check_in: check_in,
            check_out: check_out,
            number_guests: number_of_guests,
            user_id: user_id,
        });
    }

    /**
     * Monitoring the click of most popular cities section
     *
     * @param lang string
     * @param user_id string | undefined
     */
    export function clickOnMostPopularCities(lang: string, city_name: string, user_id: string | undefined = undefined) {
        // eslint-disable-next-line
        if (!(window as any)?.dataLayer || !Array.isArray((window as any)?.dataLayer)) {
            // eslint-disable-next-line
            (window as any).dataLayer = [];
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).dataLayer.push({
            event: "click_btn",
            type: "city_quick_selection",
            city_quick_selected: city_name,
            lang: lang,
            user_id: user_id,
            page_type: "homepage",
        });
    }

    /**
     * Track the information filtered after search submit
     *
     * @param lang
     * @param city_name
     * @param user_id
     */
    export function viewSearchResultsPage(
        lang: string,
        item_list_name: "search_result_page" | "homepage",
        search_term: string,
        applied_filter: string,
        check_in: string,
        check_out: string,
        booking_type: "typebooking_request" | "instant_request",
        guest_count: number = 0,
        number_days: number = 0,
        results_count: number = 0,
        results_array: RoomItem[] | [] = [],
        user_id: string | undefined = undefined,
        currency_symbol: string = "EUR",
    ) {
        // eslint-disable-next-line
        if (!(window as any)?.dataLayer || !Array.isArray((window as any)?.dataLayer)) {
            // eslint-disable-next-line
            (window as any).dataLayer = [];
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).dataLayer.push({
            event: "view_item_list",
            item_list_name: item_list_name,
            srp_number_results: results_count,
            impressions: results_array,
            language: lang,
            page_type: "search_result_page",
            number_guests: guest_count,
            search_term: search_term,
            number_days: number_days,
            applied_filter: applied_filter,
            currency: currency_symbol,
            type: booking_type,
            user_id: user_id,
            check_in: check_in,
            check_out: check_out,
        });
    }

    /**
     * Send an event every time a filter is selected
     *
     * @param lang current app language
     * @param user_id current user id if any or undefined
     */
    export function selectSerachFilter(lang: string, user_id: string | undefined = undefined) {
        // eslint-disable-next-line
        if (!(window as any)?.dataLayer || !Array.isArray((window as any)?.dataLayer)) {
            // eslint-disable-next-line
            (window as any).dataLayer = [];
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).dataLayer.push({
            event: "select",
            type: "filter_is_active",
            language: lang,
            user_id: user_id,
            page_type: "search_result_page",
        });
    }

    /**
     * Monitors filter selection of room type
     *
     * @param lang string
     * @param room_type "Hotel" | "Hostel" | "Apart"
     * @param user_id string | undefined defaults to undefined
     */
    export function selectRoomTypeFilter(
        lang: string,
        room_type: "Hotel" | "Hostel" | "Apart" | string,
        user_id: string | undefined = undefined,
    ) {
        // eslint-disable-next-line
        if (!(window as any)?.dataLayer || !Array.isArray((window as any)?.dataLayer)) {
            // eslint-disable-next-line
            (window as any).dataLayer = [];
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).dataLayer.push({
            event: "select",
            type: "filter_room_type_is_active",
            room_type: room_type,
            language: lang,
            user_id: user_id,
            page_type: "search_result_page",
        });
    }

    /**
     * Gets triggered every time price slider is adjusted
     *
     * @param lang current app language string
     * @param min_price min price of acomodations as number
     * @param max_price max price of acomodations as number
     * @param user_id string | undefined defaults to undefined
     */
    export function changePriceRangeFilter(
        lang: string,
        min_price: number,
        max_price: number,
        user_id: string | undefined = undefined,
    ) {
        // eslint-disable-next-line
        if (!(window as any)?.dataLayer || !Array.isArray((window as any)?.dataLayer)) {
            // eslint-disable-next-line
            (window as any).dataLayer = [];
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).dataLayer.push({
            event: "select",
            type: "filter_price_range",
            min_price: min_price,
            max_price: max_price,
            language: lang,
            user_id: user_id,
            page_type: "search_result_page",
        });
    }

    /**
     * Trigger upon selection of one or more house rules
     *
     * @param lang
     * @param user_id
     */
    export function selectOneOrMoreHouseRules(lang: string, user_id: string | undefined = undefined) {
        // eslint-disable-next-line
        if (!(window as any)?.dataLayer || !Array.isArray((window as any)?.dataLayer)) {
            // eslint-disable-next-line
            (window as any).dataLayer = [];
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).dataLayer.push({
            event: "select",
            type: "filter_house_rules_is_active",
            language: lang,
            user_id: user_id,
            page_type: "search_result_page",
        });
    }

    /**
     * Trigger upon selection of one or more amineties
     *
     * @param lang
     * @param user_id
     */
    export function aminetiesAreSelected(lang: string, user_id: string | undefined = undefined) {
        // eslint-disable-next-line
        if (!(window as any)?.dataLayer || !Array.isArray((window as any)?.dataLayer)) {
            // eslint-disable-next-line
            (window as any).dataLayer = [];
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).dataLayer.push({
            event: "select",
            type: "filter_amenities_is_active",
            language: lang,
            user_id: user_id,
            page_type: "search_result_page",
        });
    }

    /**
     * Trigger upon instant booking filter
     *
     * @param lang
     * @param user_id
     */
    export function clickOnInstantBookingFilter(lang: string, user_id: string | undefined = undefined) {
        // eslint-disable-next-line
        if (!(window as any)?.dataLayer || !Array.isArray((window as any)?.dataLayer)) {
            // eslint-disable-next-line
            (window as any).dataLayer = [];
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).dataLayer.push({
            event: "click_btn",
            type: "filter_instant_booking",
            language: lang,
            user_id: user_id,
            page_type: "search_result_page",
        });
    }

    /**
     * Trigger upon map full screen button
     *
     * @param lang
     * @param user_id
     */
    export function clickOnMaximizeMapButton(lang: string, user_id: string | undefined = undefined) {
        // eslint-disable-next-line
        if (!(window as any)?.dataLayer || !Array.isArray((window as any)?.dataLayer)) {
            // eslint-disable-next-line
            (window as any).dataLayer = [];
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).dataLayer.push({
            event: "click_btn",
            type: "map_arrow",
            language: lang,
            user_id: user_id,
            page_type: "search_result_page",
        });
    }

    /**
     *
     * @param lang
     * @param booking_type
     * @param room_price
     * @param guests_num
     * @param user_id
     */
    export function clickOnViewRoomDetailsCard(
        lang: string,
        booking_type: "instant_request" | "booking_request",
        room_price: number,
        guests_num: number,
        user_id: string | undefined = undefined,
    ) {
        // eslint-disable-next-line
        if (!(window as any)?.dataLayer || !Array.isArray((window as any)?.dataLayer)) {
            // eslint-disable-next-line
            (window as any).dataLayer = [];
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).dataLayer.push({
            event: "select_item",
            type: booking_type,
            value: room_price,
            currency: "EUR",
            language: lang,
            page_type: "search_result_page",
            number_guests: guests_num,
            item_list_name: "",
            user_id: user_id,
        });
    }

    /**
     * Trigger upon mounting of the room details component
     *
     * @param lang
     * @param user_id
     */
    export function viewRoomDetailsPage(
        lang: string,
        booking_type: "instant_request" | "booking_request",
        room_price: number,
        room_obj: RoomItem,
        guests_num: number,
        booking_period_in_days: number,
        check_in: string,
        check_out: string,
        page_name: "search_result_page" | "homepage",
        user_id: string | undefined = undefined,
    ) {
        // eslint-disable-next-line
        if (!(window as any)?.dataLayer || !Array.isArray((window as any)?.dataLayer)) {
            // eslint-disable-next-line
            (window as any).dataLayer = [];
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).dataLayer.push({
            event: "view_item",
            type: booking_type,
            value: room_price,
            currency: "EUR",
            language: lang,
            page_type: "product_detail_page",
            items: [room_obj],
            number_guests: guests_num,
            number_days: booking_period_in_days,
            item_list_name: page_name,
            check_in: check_in,
            check_out: check_out,
            user_id: user_id,
        });
    }

    /**
     * Trigger upon zoom in the gallery and viewing individual photots.
     *
     * @param lang
     * @param user_id
     */
    export function clickOnGalleryViewForRoomDetails(lang: string, user_id: string | undefined = undefined) {
        // eslint-disable-next-line
        if (!(window as any)?.dataLayer || !Array.isArray((window as any)?.dataLayer)) {
            // eslint-disable-next-line
            (window as any).dataLayer = [];
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).dataLayer.push({
            event: "click_btn",
            type: "view_gallery",
            language: lang,
            user_id: user_id,
            page_type: "product_detail_page",
        });
    }

    /**
     * Trigger upon toggle show/hide of amineties on the room details page
     *
     * @param lang
     * @param user_id
     */
    export function clickOnShowMoreAmenities(lang: string, user_id: string | undefined = undefined) {
        // eslint-disable-next-line
        if (!(window as any)?.dataLayer || !Array.isArray((window as any)?.dataLayer)) {
            // eslint-disable-next-line
            (window as any).dataLayer = [];
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).dataLayer.push({
            event: "click_btn",
            type: "show_more_features",
            language: lang,
            user_id: user_id,
            page_type: "product_detail_page",
        });
    }

    /**
     * Trigger upon toggle show/hide of amineties on the room details page
     *
     * @param lang
     * @param user_id
     */
    export function clickOnChangeDateButtons(lang: string, user_id: string | undefined = undefined) {
        // eslint-disable-next-line
        if (!(window as any)?.dataLayer || !Array.isArray((window as any)?.dataLayer)) {
            // eslint-disable-next-line
            (window as any).dataLayer = [];
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).dataLayer.push({
            event: "click_btn",
            type: "change_date",
            language: lang,
            page_type: "product_detail_page",
            user_id: user_id,
        });
    }

    /**
     * Trigger upon clicking booking request or instant bokking button
     * on the room details page
     *
     * @param lang
     * @param user_id
     */
    export function clickOnBookButton(
        lang: string,
        booking_type: "instant_request" | "booking_request",
        days_num: number,
        room_price: number,
        guest_num: number,
        room_obj: RoomItem,
        check_in: string,
        check_out: string,
        user_id: string | undefined = undefined,
    ) {
        // eslint-disable-next-line
        if (!(window as any)?.dataLayer || !Array.isArray((window as any)?.dataLayer)) {
            // eslint-disable-next-line
            (window as any).dataLayer = [];
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).dataLayer.push({
            event: "add_to_cart",
            type: booking_type,
            value: room_price,
            currency: "EUR",
            language: lang,
            page_type: "product_detail_page",
            items: [room_obj],
            number_guests: guest_num,
            number_days: days_num,
            check_in: check_in,
            check_out: check_out,
            user_id: user_id,
        });
    }

    /**
     * Trigger upon link click of the report feature that redirects to contact us
     *
     * @param lang
     * @param user_id
     */
    export function clickOnReportListing(lang: string, user_id: string | undefined = undefined) {
        // eslint-disable-next-line
        if (!(window as any)?.dataLayer || !Array.isArray((window as any)?.dataLayer)) {
            // eslint-disable-next-line
            (window as any).dataLayer = [];
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).dataLayer.push({
            event: "click_btn",
            type: "contact_support",
            language: lang,
            page_type: "product_detail_page",
            user_id: user_id,
        });
    }

    /**
     * Trigger upon submitting the contatct us form
     * that is generate by hubspot JS script tag
     *
     * @param lang
     * @param user_id
     */
    export function submitHubspotForm(lang: string, user_id: string | undefined = undefined) {
        // eslint-disable-next-line
        if (!(window as any)?.dataLayer || !Array.isArray((window as any)?.dataLayer)) {
            // eslint-disable-next-line
            (window as any).dataLayer = [];
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).dataLayer.push({
            event: "send_form",
            type: "send_contact_form",
            language: lang,
            user_id: user_id,
            page_type: "contact",
        });
    }

    /**
     * Trigger upon upload of profile image or phone number update
     *
     * @param lang
     * @param type
     * @param user_id
     */
    export function saveUserProfileData(
        lang: string,
        type: "save_phone_number" | "save_profile_image",
        user_id: string | undefined = undefined,
    ) {
        // eslint-disable-next-line
        if (!(window as any)?.dataLayer || !Array.isArray((window as any)?.dataLayer)) {
            // eslint-disable-next-line
            (window as any).dataLayer = [];
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).dataLayer.push({
            event: "click_btn",
            type: type,
            language: lang,
            page_type: "profile",
            user_id: user_id,
        });
    }

    /**
     * Triggered upon submiting the become a Host page
     *
     * @param lang
     * @param user_id
     */
    export function becomeAHostSubmit(lang: string, user_id: string | undefined = undefined) {
        // eslint-disable-next-line
        if (!(window as any)?.dataLayer || !Array.isArray((window as any)?.dataLayer)) {
            // eslint-disable-next-line
            (window as any).dataLayer = [];
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).dataLayer.push({
            event: "send_form",
            type: "become_a_host_form_send",
            language: lang,
            page_type: "become_a_partner",
            user_id: user_id,
        });
    }

    /**
     * Ecery time an error is throw during payment process
     *
     * @param lang current app language string
     * @param user_id string | undefined
     */
    export function paymentError(lang: string, user_id: string | undefined = undefined) {
        // eslint-disable-next-line
        if (!(window as any)?.dataLayer || !Array.isArray((window as any)?.dataLayer)) {
            // eslint-disable-next-line
            (window as any).dataLayer = [];
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).dataLayer.push({
            event: "payment_error",
            type: "payment_error",
            language: lang,
            page_type: "checkout",
            user_id: user_id,
        });
    }

    /**
     * When the checkout page mounts and the user sees any of the 3 steps
     *
     * @param lang current language selected in the app string
     * @param checkout_step "begin_checkout" | "checkout_billing_adress" | "checkout_payment_method"
     * @param booking_type enum string "booking_request" | "instant_request"
     * @param room_obj RoomItem
     * @param room_price number
     * @param num_guests number
     * @param value number
     * @param num_days number
     * @param check_in date as simple string
     * @param check_out date as simple string
     * @param user_id number | undefined
     */
    export function checkoutStepMonitoring(
        lang: string,
        checkout_step: "begin_checkout" | "checkout_billing_adress" | "checkout_payment_method" | string,
        booking_type: "booking_request" | "instant_request",
        room_obj: RoomItem,
        num_guests: number,
        num_days: number,
        check_in: string | Date,
        check_out: string | Date,
        user_id: string | undefined = undefined,
    ) {
        // eslint-disable-next-line
        if (!(window as any)?.dataLayer || !Array.isArray((window as any)?.dataLayer)) {
            // eslint-disable-next-line
            (window as any).dataLayer = [];
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).dataLayer.push({
            event: checkout_step,
            type: booking_type,
            value: room_obj.price,
            currency: "EUR",
            language: lang,
            page_type: "checkout",
            items: [room_obj],
            number_guests: num_guests,
            number_days: num_days,
            check_in: check_in,
            check_out: check_out,
            user_id: user_id,
        });
    }

    /**
     * Selection of a payment method
     *
     * @param lang
     * @param booking_type
     * @param payment_type
     * @param room_obj RoomItem
     * @param room_price number
     * @param num_guests number
     * @param num_days number
     * @param check_in date as simple string
     * @param check_out date as simple string
     * @param user_id number | undefined
     */
    export function checkoutStepSelectPayment(
        lang: string,
        booking_type: "booking_request" | "instant_request",
        payment_type: "Credit Card" | "PayPal",
        room_obj: RoomItem,
        num_guests: number,
        num_days: number,
        check_in: string | Date,
        check_out: string | Date,
        user_id: string | undefined = undefined,
    ) {
        // eslint-disable-next-line
        if (!(window as any)?.dataLayer || !Array.isArray((window as any)?.dataLayer)) {
            // eslint-disable-next-line
            (window as any).dataLayer = [];
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).dataLayer.push({
            event: "add_payment_info",
            type: booking_type,
            currency: "EUR",
            language: lang,
            page_type: "checkout",
            payment_type: payment_type,
            items: [room_obj],
            number_guests: num_guests,
            number_days: num_days,
            check_in: check_in,
            check_out: check_out,
            user_id: user_id,
        });
    }

    /**
     * Send the final purchase event to google tag manager
     *
     * @param lang
     * @param reservationData
     */
    export function checkoutFinalSendPurchase(
        lang: string,
        reservationData: ReservationDoc,
    ) {
        // eslint-disable-next-line
        if (!(window as any)?.dataLayer || !Array.isArray((window as any)?.dataLayer)) {
            // eslint-disable-next-line
            (window as any).dataLayer = [];
        }

        let totalPeriodValue = 0,
            totalTax = 0,
            totalCommission = 0,
            firstInstllment = 0;
        firstInstllment = (reservationData?.payments[0].grossTotalAmountForGuest ?? 0) / 100;
        reservationData?.payments.forEach((installment) => {
            totalPeriodValue += installment.accommodationPrice / 100;

            totalTax += (installment.netServiceFeeAmount / 100) * (19 / 100);
            totalTax += installment.taxAmountForHost / 100;

            totalCommission += installment.grossServiceFeeAmount / 100;
            totalCommission += installment.grossHostFeeAmount / 100;

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (window as any).dataLayer.push({
                event: "purchase",
                type: reservationData?.bookingType === BookingType.Request ? "booking_request" : "instant_request",
                value: totalPeriodValue,
                currency: "EUR", // For now this is EUR only
                language: lang === "de" ? "de" : "en",
                page_type: "checkout",
                tax: totalTax, // (serviceFee + host fee for total) 19%
                transaction_id: reservationData?._id, // reservation id or booking number
                period: "long",
                number_guests: reservationData?.visitors?.length,
                number_nights: reservationData?.nights.toString(), // string not integer
                user_email: reservationData?.guest?.account?.email,
                user_phone: reservationData?.guest?.phoneNumbers[0],
                user_first_name: reservationData?.guest?.firstName,
                user_last_name: reservationData?.guest?.lastName,
                user_street_name: reservationData?.guest?.address?.street,
                user_country: reservationData?.guest?.address?.country,
                user_zip_code: reservationData?.guest?.address?.zipCode,
                user_city: reservationData?.guest?.address?.city,
                commission: totalCommission,
                items: [
                    {
                        item_id: reservationData?.roomCategory._id,
                        hotel_name: reservationData?.roomCategory?.property?.name ?? "",
                        hotel_category: reservationData?.roomCategory?.roomType,
                        hotel_room_name: reservationData?.roomCategory?.name ?? "",
                        hotel_city: reservationData?.roomCategory?.property?.address?.city ?? "",
                        price: firstInstllment, // without tax (first installment the same as in the room details roomsummary grey bar)
                    },
                ],
            } as unknown as PurchaseEvent);
        });
    }
}
