"use client";

import { LanguageParams } from "@/lib/types/PageProps";
import Text from "@/lib/components/Text";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/sharp-solid-svg-icons";
import { getLanguageData, languageData } from "@/lib/i18n/settings";
import { useEffect, useRef, useState, JSX, useMemo } from "react";
import { Popover } from "@headlessui/react";
import { useClickAway } from "@uidotdev/usehooks";
import { useTranslation } from "@/lib/i18n/client";
import LanguageSelectorIcon from "./LanguageSelectorIcon";
import LanguageSelectorItem from "./LanguageSelectorItem";
import Modal from "../Modal";
import ModalHeader from "../Modal/ModalHeader";
import { UrlTranslation, Urls } from "@/lib/constants/urls";
import { usePathname, useSearchParams } from "next/navigation";

 // Extract city name from the URL
 // eslint-disable-next-line @typescript-eslint/no-unused-vars
 export const getCityFromURL = () => {
  
    // Ensure the function runs only on the client-side
    if (typeof window === "undefined") {
        return null;
    }
    
    const url = new URL(window.location.href); // Get the current URL
    const pathname = url.pathname; // Extract the pathname from the URL
    
    // Match '/<lang>/s/<city>' where <lang> is any two-letter language code
    const cityMatch = pathname.match(/^\/(?:[a-z]{2}\/)?s\/([^/]+)/);
    
    // Decode and return the city if matched, replacing '+' with spaces
    return cityMatch ? decodeURIComponent(cityMatch[1]).replace(/\+/g, " ") : null;
  };

  const currentCityfetch: string | null = getCityFromURL();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const currentCity: string = currentCityfetch ?? "nocity"; // Fallback to "default value" if null

type Props = {
    showText?: boolean;
    dark?: boolean;
    mode?: "down" | "up" | "modal";
    isMobileModalOpen?: boolean;
    onCloseMobileModal?: () => void;
} & LanguageParams;

type Position = {
    top?: number;
    right?: number;
    bottom?: number;
    left?: number;
};

type Url = {
    original: string;
    translation: string;
};

const LanguageSelector = ({
    lang,
    showText = false,
    dark = false,
    mode = "down",
    isMobileModalOpen = false,
    onCloseMobileModal,
}: Props) => {
    const { t } = useTranslation(lang, "menu");
    const currentLangaugeData = getLanguageData(lang);
    const languageButton = useRef<HTMLDivElement>(null);
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const [popupLocation, setPopupLocation] = useState<Position>({ right: 0, bottom: 0 });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const pathname = usePathname();
    const searchParams = useSearchParams();

    const languageMappings = useMemo(() => {
        const getLangMapping = (language: string) => {
            const getUrl = (obj: object): Url[] => {
                if (typeof obj === "string") {
                    if ((obj as string).startsWith("http")) {
                        return [];
                    }
                    return [{ original: obj as string, translation: obj as string }];
                } else if ((obj as UrlTranslation).original) {
                    const url = obj as UrlTranslation;
                    return [{ original: url.original, translation: Object.keys(url.translations).includes(language) ? url.translations[language] : url.original }];
                }
                return Object.keys(obj).map(k => getUrl((obj as Record<string, object>)[k])).flat();
            };
            return getUrl(Urls).map(u => [u.original, u.translation]);
        };
        const r: Record<string, string[][]> = {};
        for (const l of Object.keys(languageData)) {
            r[l] = getLangMapping(l);
        }
        return r;
    }, []);

    useEffect(
        () => {
            if (isModalOpen !== isMobileModalOpen) {
                setIsModalOpen(isMobileModalOpen);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isMobileModalOpen],
    );

    const closeLanguagePopover = (event: Event) => {
        const node = event.target as Node;
        if (!languageButton.current?.contains(node)) {
            setIsPopoverOpen(false);
        }
    };
    const refLanguagePopover = useClickAway(closeLanguagePopover);

    const toggleLanguagePopover = () => {
        if (mode === "modal") {
            setIsModalOpen(!isMobileModalOpen);
            return;
        }

        if (languageButton.current) {
            // also set coordinates
            const rect = languageButton.current.getBoundingClientRect();
            setPopupLocation(
                mode === "down"
                    ? {
                        top: rect.height / 2,
                        left: -(rect.width / 2),
                    }
                    : {
                        bottom: rect.height,
                        right: 0,
                    },
            );
        }
        setIsPopoverOpen(!isPopoverOpen);
    };

    const closeMobileModal = () => {
        if (onCloseMobileModal) {
            setIsModalOpen(false); // this happens from parent anyway, but setting it here just in case
            onCloseMobileModal();
        }
    };

    return (
        <div className="flex items-center text-base select-none">
            <div
                onClick={toggleLanguagePopover}
                className={`flex gap-2 items-center cursor-pointer`}
                ref={languageButton}
            >
                <LanguageSelectorIcon
                    flagIcon={currentLangaugeData.flagIcon}
                    name={currentLangaugeData.localName}
                    dark={dark}
                />
                {showText && (
                    <Text as="span" className={`font-medium text-sm ${dark ? "text-black" : "text-white"}`}>
                        {currentLangaugeData.localName}
                    </Text>
                )}
                <FontAwesomeIcon icon={isPopoverOpen ? faChevronUp : faChevronDown} size="xs" />
            </div>
            {isPopoverOpen && (
                <Popover ref={refLanguagePopover as React.Ref<HTMLElement>} className="relative" style={popupLocation}>
                    <Popover.Panel
                        static
                        className={`absolute z-10 select-none w-72 ${mode === "down" ? "top-2 -ml-36" : "-ml-72 -mt-full"
                            } text-black text-left font-medium text-sm`}
                        style={{ bottom: 0 }}
                    >
                        <div className="">
                            {mode === "down" && (
                                <div className="flex justify-center">
                                    <div className="h-0 w-0 border-x-[12px] border-x-transparent border-b-[10px] border-b-gray-200"></div>
                                </div>
                            )}
                            <div className="bg-white -mt-px rounded-lg border border-gray-200 shadow-md">
                                <Text className="inline-block font-bold text-sm text-center p-4 w-full">
                                    {t("select-your-language")}
                                </Text>
                                <ul>
                                    <LanguageSelectorItem
                                        lang={lang}
                                        targetLanguage={lang}
                                        entryData={currentLangaugeData}
                                        currentPath={pathname.replace(`/${lang}/`, "")}
                                        searchParams={searchParams}
                                        languageMappings={languageMappings}
                                        current={true}
                                        currentCity={currentCity}
                                    />
                                    {Object.keys(languageData)
                                        .filter((l) => l !== lang)
                                        .map((l) => (
                                            <LanguageSelectorItem
                                                key={l}
                                                lang={lang}
                                                targetLanguage={l}
                                                currentPath={pathname.replace(`/${lang}/`, "")}
                                                searchParams={searchParams}
                                                languageMappings={languageMappings}
                                                entryData={languageData[l]}
                                                currentCity={currentCity}
                                            />
                                        ))}
                                </ul>
                            </div>
                            {mode !== "down" && (
                                <div className="flex justify-end pr-2">
                                    <div className="h-0 w-0 border-x-[12px] border-x-transparent border-t-[10px] border-t-gray-200"></div>
                                </div>
                            )}
                        </div>
                    </Popover.Panel>
                </Popover>
            )}
            {isModalOpen && (
                <Modal open={isModalOpen} setOpen={() => null} mode="full-screen" onClose={closeMobileModal} hideOnLg>
                    <div className="flex flex-col h-full">
                        <ModalHeader
                            title={t("select-your-language")}
                            onClose={closeMobileModal}
                            className="p-4 lg:pd-5"
                        />
                        <div className="flex flex-col gap-1.5 xl:hidden pt-8 font-medium text-left">
                            <ul>
                                <LanguageSelectorItem
                                    lang={lang}
                                    targetLanguage={lang}
                                    entryData={currentLangaugeData}
                                    currentPath={pathname.replace(`/${lang}/`, "")}
                                    searchParams={searchParams}
                                    languageMappings={languageMappings}
                                    current={true}
                                    currentCity={currentCity}
                                />
                                {Object.keys(languageData)
                                    .filter((l) => l !== lang)
                                    .map((l) => (
                                        <LanguageSelectorItem
                                            key={l}
                                            lang={lang}
                                            targetLanguage={l}
                                            currentPath={pathname.replace(`/${lang}/`, "")}
                                            searchParams={searchParams}
                                            languageMappings={languageMappings}
                                            entryData={languageData[l]}
                                            currentCity={currentCity}
                                        />
                                    ))}
                            </ul>
                        </div>
                    </div>
                </Modal>
            )}
        </div>
    );
};

export default LanguageSelector as unknown as (props: Props) => JSX.Element;
