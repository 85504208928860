import { Urls, getUrl } from "@/lib/constants/urls";
import { UserAtom } from "@/lib/jotai/auth/authStore";
import { API } from "@/lib/services/authService";
import { getBackend, getDomain } from "@/sites";
import { ReadonlyURLSearchParams } from "next/navigation";

const getClosestMapping = (currentPath: string, mapping: string[][], backward: boolean): string[] | undefined => {
    // this handles variables in urls, gets closest matching url
    // backward - if true it looks for mapping of non-default language
    const result = mapping.find((m) => m[backward ? 1 : 0] === currentPath);
    if (!result) {
        const splitPath = currentPath.split("/").filter((p) => p);
        if (splitPath.length > 1) {
            return getClosestMapping([...splitPath.slice(0, -1), ""].join("/"), mapping, backward);
        }
    }
    return result;
};

const getLangaugeLink = (
    currentPath: string,
    searchParams: ReadonlyURLSearchParams,
    languageMappings: Record<string, string[][]>,
    targetLanguage: string,
    currentLanguage: string,
    currentCity: string,
) => {
    const searchParamsStr = searchParams.toString() ? `?${searchParams}` : "";
    if (currentPath.startsWith("/") && currentPath !== "/") {
        currentPath = currentPath.replace("/", "");
    }

  /*  if (process.env.NODE_ENV === "development") {
        const closestMapping = getClosestMapping(currentPath, languageMappings[currentLanguage], true);
        let path = currentPath;
        if (closestMapping) {
            path = closestMapping[0];
        }
        if (path.startsWith("/")) {
            path = path.replace("/", "");
        }
        return `/${targetLanguage}/${path}${searchParamsStr}`;
    }*/

    // get closest matching url - get current lang and then find target lang url
    const original = getClosestMapping(currentPath, languageMappings[currentLanguage], true);

    // eslint-disable-next-line prefer-const
    let { domain, isCustomDomain } = getDomain(targetLanguage);
    console.log("yeh domain " + domain + " yeh custom domain " +isCustomDomain);
    const cleanUp = (url: string) => {
        // safari < 16.4 hack:
        const urlSplit = url.split("://");
        if (urlSplit.length === 1) {
            return urlSplit[0].replace(/\/\//gm, "/");
        }
        return `${urlSplit[0]}://${urlSplit[1].replace(/\/\//gm, "/")}`;
    };

    if (!original) {

       const check =  cleanUp(domain + getUrl(Urls.home.index, targetLanguage));
      
        if(!currentCity || currentCity=="nocity" || !check.includes("/s/")){
          return cleanUp(domain + getUrl(Urls.home.index, targetLanguage)) + searchParamsStr;
        }else {
          return cleanUp(domain + getUrl(Urls.home.index, targetLanguage)) + currentCity + searchParamsStr;
        }
        
    }

    const target = getClosestMapping(original[0], languageMappings[targetLanguage], false);

    if (!target) {

      const check2 =  cleanUp(domain + getUrl(Urls.home.index, targetLanguage));

        if(!currentCity || currentCity=="nocity" || !check2.includes("/s/")){
          return cleanUp(domain + getUrl(Urls.home.index, targetLanguage)) + searchParamsStr;
        }else {
          return cleanUp(domain + getUrl(Urls.home.index, targetLanguage)) + currentCity + searchParamsStr;
        }

        
    }

    const check3 =  cleanUp(domain + (`/${targetLanguage}/`) + target[1]);

    if(!currentCity || currentCity=="nocity" || !check3.includes("/s/")){
      // iscustomDomain set or not, we are going to add language parameter in URL anyways
      //return cleanUp(domain + (isCustomDomain ? "" : `/${targetLanguage}/`) + target[1]) + searchParamsStr;
      return cleanUp(domain + (`/${targetLanguage}/`) + target[1]) + searchParamsStr;
    }else {
      //return cleanUp(domain + (isCustomDomain ? "" : `/${targetLanguage}/`) + target[1]) + currentCity + searchParamsStr;
      return cleanUp(domain + (`/${targetLanguage}/`) + target[1]) + currentCity + searchParamsStr;
    }

    
};

export function getOnLanguageLink(
    currentPath: string,
    searchParams: ReadonlyURLSearchParams,
    languageMappings: Record<string, string[][]>,
    targetLanguage: string,
    currentLanguage: string,
    currentCity: string,
    auth: UserAtom,
    checkoutForms: object,
) {
    return async (e: React.SyntheticEvent<HTMLAnchorElement, Event>) => {
        e.stopPropagation();
        // check if different backends - if same, just redirect to link
        const link = getLangaugeLink(currentPath, searchParams, languageMappings, targetLanguage, currentLanguage, currentCity);
        const currentBackendHost = getBackend(currentLanguage);
        const targetBackendHost = getBackend(targetLanguage) ?? currentBackendHost ?? "";
        console.log("yeh ha bna hoa linkn ", link);
        if (currentBackendHost === targetBackendHost || !auth.isLoggedIn) {
            window.location.href = link;
            return;
        }
// eslint-disable-next-line @typescript-eslint/no-unused-vars
        const tokenResponse = await API.SendStorage({
            userInfo: auth,
            redirectUri: link,
            checkoutForms: checkoutForms,
        });

        window.location.href = `${link}#t=${tokenResponse.token}`;
    };
}
